import React, { useState } from 'react'
//@ts-ignore
import Papa from 'papaparse';
import "../../Utils/DefinitionsInterfaces";

//@ts-ignore
function CsvParser(file, schoolID, cbHandleSettingStudentObject ) {
  console.log("SchoolID received = ", schoolID);
  const studentsDetails: ApiRequestAddStudents = {
    students: [],
  };
  let errorMessage = "";
  console.log("StudentsDetails Object INITIAL = ", studentsDetails);
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    
    complete: function(results: studentsFileDetails) {
      console.log(results.data);
      console.log(results.data.length);
      let validData = true;
      const phoneRegex = /^\d{10}$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      for(let i = 0; (i < results.data.length) && (true === validData) ; i++ )
      {
        const studentDetail = {
          schoolID: parseInt(schoolID),
          name: results.data[i].name,
          phone: results.data[i].phone,
          email: results.data[i].email,
          grade: parseInt(results.data[i].grade),
        }
        studentsDetails.students.push(studentDetail);
        if(  (studentDetail.name === undefined) 
          || ((studentDetail.phone === undefined) || (!phoneRegex.test(studentDetail.phone)))
          || ((studentDetail.email === undefined) || (!emailRegex.test(studentDetail.email)))
          || ((Number.isNaN(studentDetail.grade)) || (studentDetail.grade <=0 || studentDetail.grade > 12))
        )
        {
          console.log("At least one wrong entry in field "+(i+1)+". Details = ",results.data[i]);
          errorMessage="Either Column Titles are wrong/typo OR at least Entry "+(i+1)+" is wrong in the csv file";
          validData = false;
        }
      }
      console.log("StudentsDetails in format = ",studentsDetails);
      console.log("In json format : ", JSON.stringify(studentsDetails));
      cbHandleSettingStudentObject(studentsDetails, errorMessage);
    },
  });
  console.log("StudentsDetails Object = ", studentsDetails);
  return studentsDetails;
}

export default CsvParser;