import { Modal } from "@mui/material";
import React from "react";

interface ModalProps {
  onClose: () => void;
  open: boolean;
  title: string;
  children: React.ReactNode;
  alignTop?: boolean;
}

const ModalComponent: React.FC<ModalProps> = ({
  onClose,
  open,
  title,
  children,
  alignTop,
}) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      style={{
        display: "flex",
        alignItems: alignTop ? "start" : "center",
        justifyContent: "center",
        overflow: "scroll",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
    >
      <>
        <div
          style={{
            background: "white",
            borderRadius: 20,
            padding: "20px",
            maxWidth: "300px",

            paddingLeft: "15px",
            paddingRight: "15px",
            alignItems: "center",
            justifyContent: "center",

          }}
        >
          <div
            style={{
              textAlign: "center",
              color: "#2C6AB1",
              fontSize: 20,
              marginBottom: "20px",
            }}
          >
            {title}
          </div>
          {children}
        </div>
      </>
    </Modal>
  );
};

export default ModalComponent;
