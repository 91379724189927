import React, { ChangeEvent, useState } from "react";
import ModalComponent from "./ModalComponent";
import { BootstrapInput } from "../InputField";
import { loadingIcon } from "../../Assets/Images";

interface UpdateStudentEmailModalComponentProp {
  stateOpenEditEmail: boolean;
  stateStudentEditDetails: { newMobileNumber: string; newEMail: string };
  stateStudentDetailsErrors: { number: string; email: string };

  cbHandleCloseEditEmail: () => void;
  cbHandleInputEmail: (e: ChangeEvent<HTMLInputElement>) => void;
  cbHandleSubmitEmailId: (e: any) => void;
}

const UpdateStudentEmailModalComponent: React.FC<
  UpdateStudentEmailModalComponentProp
> = (props: UpdateStudentEmailModalComponentProp) => {
  console.log("In component UpdateStudentEmailModalComponent");
  const [loading, setLoading] = useState(false);
  return (
    <ModalComponent
      onClose={props.cbHandleCloseEditEmail}
      open={props.stateOpenEditEmail}
      title="Update Student Email ID"
    >
      <>
        <div
          style={{
            background: "white",
            borderRadius: 20,
            height: "fit-content",
            border: "none #000",
            margin: "20px",
          }}
        >
          <div className="modal-inputs">
            <BootstrapInput
              onChange={props.cbHandleInputEmail}
              name="newEMail"
              className="input"
              type="email"
              placeholder="email id"
              value={props.stateStudentEditDetails.newEMail}
            />
            {!props.stateStudentEditDetails.newEMail.length && (
              <div
                style={{
                  color: "red",
                  display: "auto",
                  marginTop: "10px",
                }}
              >
                Incorrect format / missing data
              </div>
            )}
            {props.stateStudentDetailsErrors.email.length != 0 && (
              <div
                style={{
                  color: "red",
                  display: "auto",
                  marginTop: "10px",
                }}
              >
                {props.stateStudentDetailsErrors.email}
              </div>
            )}
            <button
              className="modal-submit-button"
              disabled={
                !props.stateStudentEditDetails.newEMail.length || loading
              }
              onClick={(e) => {
                setLoading(true);
                props.cbHandleSubmitEmailId(e);
                setLoading(false);
              }}
              style={{
                alignSelf: "center",
                padding: "4%",
                fontSize: 16,
                whiteSpace: "nowrap",
              }}
            >
              {loading && <img src={loadingIcon} alt="Loading" />}
              {!loading && <a>Update Email</a>}
            </button>
          </div>
        </div>
      </>
    </ModalComponent>
  );
};

export default UpdateStudentEmailModalComponent;
