import axios, { AxiosError } from "axios";

const api = axios.create({
  baseURL: "https://onboarding.streakcard.click/nfo", // Replace with your API base URL
});

// Function to refresh the access token using the refresh token
const refreshAccessToken = async () => {
  try {
    const response = await axios.post(
      "https://onboarding.streakcard.click/nfo/refresh-token",
      {
        refresh_token: localStorage.getItem("nfoAdminRefreshToken"), // Replace with your implementation to retrieve refresh token
      }
    );

    const newAccessToken = response.data.token;
    const newRefreshToken = response.data.refresh_token;
    localStorage.setItem("nfoAdminAccessToken", newAccessToken);
    localStorage.setItem("nfoAdminRefreshToken", newRefreshToken);
    return newAccessToken;
  } catch (error: any) {
    console.log("this is the error", error.response.status);
    if (
      error.response.status == 400 &&
      error.response?.data?.message?.includes("token")
    ) {
      // Handle refresh token failure (e.g., logout user)
      console.error("Failed to refresh access token:", error);
      // Perform logout or any other action here
      // Example: logoutUser();
      sessionStorage.clear();
      window.location.reload();
      localStorage.clear();
      throw error;
    }
  }
};

// Axios request interceptor for adding the access token to requests
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("nfoAdminAccessToken");
    if (accessToken) {
      config.headers.Authorization = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios response interceptor to refresh the access token if it's expired
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to an expired token
    if (
      error.response.status === 400 &&
      !originalRequest._retry &&
      originalRequest.url !== "admin-login-send-otp" &&
      originalRequest.url !== "admin-login-verify-otp" &&
      originalRequest.url !== "get-student-details" &&
      originalRequest.url !== "update-exam-link" &&
      originalRequest.url !== "invite-students-for-scholl" &&
      originalRequest.url !== "invite-student" &&
      originalRequest.url !== "notify-student" &&
      originalRequest.url !== "notify-school-students"
    ) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();
        originalRequest.headers.Authorization = newAccessToken;
        return api(originalRequest);
      } catch (refreshError: any) {
        console.log(refreshError);
        if (
          error.response.status == 400 &&
          error.response.message.includes("token")
        ) {
          // If refresh fails, perform logout or any other action
          console.error("Failed to refresh access token:", refreshError);
          sessionStorage.clear();
          window.location.reload();
          localStorage.clear();
          throw refreshError;
        }
      }
    }

    return Promise.reject(error);
  }
);

export default api;
