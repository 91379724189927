import React from "react";
import TextField from "@mui/material/TextField";

interface FilterComponentProps {
  onFilterChange: (filterText: string) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  onFilterChange,
}) => {
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onFilterChange(value);
  };

  return (
    <div>
      <TextField
        sx={{
          width: 250, // Specify the width
          "& .MuiInputBase-root": {
            height: 38,
            borderRadius: "40px",
          },
          "& .MuiInputLabel-root.MuiInputLabel-shrink": {
            // Shrink state
            top: "0px !important",
          },
        }}
        InputLabelProps={{
          style: {
            color: "rgb(44, 106, 177)",
            top: "-8px",
          },
        }}
        label="Filter by School Name"
        onChange={handleFilterChange}
      />
    </div>
  );
};

export default FilterComponent;
