import { DataGrid, GridColDef, GridRowHeightParams } from "@mui/x-data-grid";
import React from "react";
import "../css/List.css";
import "../Utils/Constants";
import { DATAGRID_ROW_INTERNAL_PADDING, ELEMENT_HEIGHT_STUDENT_DETAIL } from "../Utils/Constants";

interface TableViewProps {
  columns: GridColDef[];
  rows: any[];
}

export const TableView: React.FC<TableViewProps> = (props) => {
  const columns = props.columns || [];
  const rows = props.rows || [];

  const gridOptions = {
    rows: rows,
    columns: columns,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
  };

  const customStyles = {
    cell: {
      height: "100px",
     
    },
  };

  function getRowHeight(heightParamsObject: GridRowHeightParams) {
    // console.log("Parameter Height Object = ",heightParamsObject);
    const numEle = heightParamsObject.model.col2.length;
    // console.log("The number of subrows = ", numEle);
    return (numEle * ELEMENT_HEIGHT_STUDENT_DETAIL) + (2 * DATAGRID_ROW_INTERNAL_PADDING);
  };


  return (
    <div style={{ height: "60vh", width: "100%" }}>
      <DataGrid 
        sx={{
          '& .MuiDataGrid-cell:focus': {
            outline: "none",
          },
          '& .MuiDataGrid-main': {
            // borderRadius: "100px"
            backgroundColor: "rgb(243, 243, 243)"
          },
          '& .MuiDataGrid-cell': {
            display: "inline",
            paddingTop: "20px"
          },
        }}
        getRowHeight={getRowHeight}
        {...gridOptions}
        className="customStyles"
      />
    </div>
  );
};
