import React from "react";

//@ts-ignore
import Papa from "papaparse";
import "../../Utils/DefinitionsInterfaces";

//@ts-ignore
const CsvParserResults = (file, schoolID, cbHandleSettingStudentObject) => {
  console.log("SchoolID received = ", schoolID);
  const resultsDetails: ApiRequestUploadResults = {
    studentReport: [],
    schoolID: schoolID,
  };
  let errorMessage = "";
  console.log("StudentsDetails Object INITIAL = ", resultsDetails);
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,

    complete: function (results: resultsFileDetails) {
      console.log(results.data);
      console.log(results.data.length);
      let validData = true;
      for (let i = 0; i < results.data.length && true === validData; i++) {
        const studentDetail = {
          StudentUsername: results.data[i].StudentUsername,
          TimeTaken: results.data[i].TimeTaken,
          Money: parseInt(results.data[i].Money),
          Money_FullScore: parseInt(results.data[i].Money_FullScore),
          Financial_Planning: parseInt(results.data[i].Financial_Planning),
          Financial_Planning_FullScore: parseInt(
            results.data[i].Financial_Planning_FullScore
          ),
          Banking: parseInt(results.data[i].Banking),
          Banking_FullScore: parseInt(results.data[i].Banking_FullScore),
          Investments: parseInt(results.data[i].Investments),
          Investments_FullScore: parseInt(
            results.data[i].Investments_FullScore
          ),
          Loans_And_Credit: parseInt(results.data[i].Loans_And_Credit),
          Loans_And_Credit_FullScore: parseInt(
            results.data[i].Loans_And_Credit_FullScore
          ),
          Tax_Planning: parseInt(results.data[i].Tax_Planning),
          Tax_Planning_FullScore: parseInt(
            results.data[i].Tax_Planning_FullScore
          ),
        };
        resultsDetails.studentReport.push(studentDetail);
        if (studentDetail.StudentUsername === undefined) {
          console.log(
            "At least one wrong entry in field " + (i + 1) + ". Details = ",
            results.data[i]
          );
          errorMessage =
            "UserName is wrong in the current field " +
            (i + 1) +
            " is wrong in the csv file";
          validData = false;
        }
      }
      console.log("StudentsDetails in format = ", resultsDetails);
      console.log("In json format : ", JSON.stringify(resultsDetails));
      cbHandleSettingStudentObject(resultsDetails, errorMessage);
    },
  });
  console.log("StudentsDetails Object = ", resultsDetails);
  return resultsDetails;
};

export default CsvParserResults;
