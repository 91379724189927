import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import "../css/List.css";
import "../Utils/DefinitionsInterfaces";
import {
  MAX_SCHOOLS_IN_PAGE1,
  MAX_SCHOOLS_IN_PAGE2,
  MAX_SCHOOLS_IN_PAGE3,
} from "../Utils/Constants";

interface TableViewProps {
  columns: GridColDef[];
  rows: any[];
}

export const TableView: React.FC<TableViewProps> = function (props) {
  const columns = props.columns || [];
  const rows = props.rows || [];

  const customStyles = {
    cell: {
      height: "100px",
    },
  };

  const gridOptions = {
    rows: rows,
    columns: columns,
    rowHeight: 150,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
  };

  function getRowId(row: Row) {
    // console.log("Row.id = ", row.id);
    return row.id;
  }

  return (
    <div style={{ height: "56vh", width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            color: "gray",
            minHeight: "30px",
          },
          "& .MuiTablePagination-root": {
            color: "gray",
          },
        }}
        getRowId={getRowId}
        {...gridOptions}
        className="customStyles"
        pagination
        paginationMode="client"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: MAX_SCHOOLS_IN_PAGE1,
            },
          },
        }}
        pageSizeOptions={[
          MAX_SCHOOLS_IN_PAGE1,
          MAX_SCHOOLS_IN_PAGE2,
          MAX_SCHOOLS_IN_PAGE3,
        ]}
      />
    </div>
  );
};
