import axios from "axios";
import api from "../axios/axiosMW";

export interface ServerResponseGeneral {
  status: number;
  message: "verify otp";
  errorCode: 0;
}

interface sendOtpResponse extends ServerResponseGeneral {
  data: string | Object;
}
interface verifyOtpResponse extends ServerResponseGeneral {
  data: string | Object;
}

export interface adminData {
  created_at: string;
  user_id: string;
  name: string;
  mobile_number: string;
  otp_verified: boolean;
}

interface ApiGetAdminDetailsResponse extends ServerResponseGeneral {
  data: adminData;
}

export interface StudentForSchool {
  student_username: string;
  name: string;
  grade: number;
  overall_score_percentage: number | null;
  rank: number | null;
}

export interface getAllStudentsResponse extends ServerResponseGeneral {
  data: {
    students: StudentForSchool[];
  };
}

export interface TeachersForSchool {
  teacher_username: string;
  name: string;
  phone: string;
  email: string;
}

export interface getAllTeachersResponse extends ServerResponseGeneral {
  data: {
    teachers: TeachersForSchool[];
  };
}

/**
 *
 * @param adminUserID
 * @returns
 */
export function sendOtp(adminUserID: string): Promise<sendOtpResponse> {
  console.log("Sending OTP");
  return api.post("admin-login-send-otp", {
    adminUserID,
  });
}

/**
 *
 * @param data
 * @returns
 */
export function verifyOtp(data: {
  adminUserID: string;
  otp: string;
}): Promise<verifyOtpResponse> {
  console.log("Verifying OTP");
  return api.post("admin-login-verify-otp", {
    ...data,
  });
}

export function getAdminDetails(): Promise<ApiGetAdminDetailsResponse> {
  return api.post("admin-details", {});
}

export function getAllStudent(
  schoolID: number
): Promise<getAllStudentsResponse> {
  return api.post("get-all-students", { schoolID });
}

export function getAllTeachers(
  schoolID: number
): Promise<getAllTeachersResponse> {
  return api.post("get-all-teacher", { schoolID });
}

// Nationals qualified
export interface NationalsStudents {
  data: {
    username: string,
    studentName: string,
    grade: number,
    phone: string,
    email: string,
    schoolName: string
  }[]
}

interface GetNationalsStudentsResponse extends ServerResponseGeneral {
  data: NationalsStudents
}

export function getNationalsStudents(): Promise<GetNationalsStudentsResponse> {
  return api.post("nationals-qualified-students")
}

export interface NationalsData {
  data: {
    invited: boolean,
    // check the field
    examNotified: boolean,
    reportIssued: boolean,
    examDate: string,
    totalNumberOfStudents: number,
    examLinkGenerated: boolean
  }
}

interface GetNationalsDataResponse extends ServerResponseGeneral {
  data: NationalsData
}

export function getNationalsData(): Promise<GetNationalsDataResponse> {
  return api.post("nationals-master-data")
}