import React, { useContext, useState } from "react";
import ModalComponent from "./ModalComponent";
//@ts-ignore
import ReactFileReader from "react-file-reader";
import { loadingIcon } from "../../Assets/Images";
import { AdminContext } from "../../Contexts/AdminContext";

interface AddStudentsModalComponentProp {
  stateOpenAddStudents: {
    isOpen: boolean;
    selectedId: number | null;
  };
  stateStudentsAddFilename: string;
  stateAddStudentsObject: any;
  stateCsvParseErrorMessage: string;
  stateParsingInProgress: boolean;
  statePresSchoolId: number;

  cbHandleCloseAddStudent: () => void;
  cbHandleInputStudentsFile: (e: any) => void;
  cbHandleSubmitAddStudents: (e: any, newSchoolID: number) => void;
}

const AddStudentsModalComponent: React.FC<AddStudentsModalComponentProp> = (
  props: AddStudentsModalComponentProp
) => {
  const { selectedExamState } = useContext(AdminContext)
  const [loading, setLoading] = useState(false);
  return (
    <ModalComponent
      onClose={props.cbHandleCloseAddStudent}
      open={props.stateOpenAddStudents.isOpen}
      title="Upload student data CSV to add them to school"
    >
      <>
        <div
          style={{
            background: "white",
            borderRadius: 20,
            height: 134,
            border: "none #000",
          }}
        >
          <div className="modal-inputs">
            <ReactFileReader
              fileTypes={[".csv", ".zip"]}
              base64={true}
              handleFiles={props.cbHandleInputStudentsFile}
            >
              <button
                className="input"
                style={{ justifyContent: "left", cursor: "pointer" }}
              >
                {props.stateStudentsAddFilename}
              </button>
            </ReactFileReader>
            {"Choose File" === props.stateStudentsAddFilename && (
              <div
                style={{
                  color: "red",
                  display: "auto",
                }}
              >
                Incorrect format / missing data
              </div>
            )}
            {"Choose File" !== props.stateStudentsAddFilename &&
              false === props.stateParsingInProgress &&
              (selectedExamState === 'level1' ?
                props.stateAddStudentsObject.students.length === 0
                : props.stateAddStudentsObject.studentUserNames.length === 0
              ) && (
                <div
                  style={{
                    color: "red",
                    display: "auto",
                  }}
                >
                  File Empty / invalid contents
                </div>
              )}
            {"Choose File" !== props.stateStudentsAddFilename &&
              "" !== props.stateCsvParseErrorMessage &&
              false === props.stateParsingInProgress && (
                <div
                  style={{
                    color: "red",
                    display: "auto",
                  }}
                >
                  Error: {props.stateCsvParseErrorMessage}
                </div>
              )}
            {"Choose File" !== props.stateStudentsAddFilename &&
              true === props.stateParsingInProgress && (
                <div
                  style={{
                    color: "green",
                    display: "auto",
                  }}
                >
                  PARSING IN PROGRESS ......
                </div>
              )}
            <button
              className="modal-submit-button"
              disabled={
                "Choose File" === props.stateStudentsAddFilename ||
                "" !== props.stateCsvParseErrorMessage ||
                (selectedExamState === 'level1' ?
                  props.stateAddStudentsObject.students.length === 0
                  : props.stateAddStudentsObject.studentUserNames.length === 0
                ) ||
                true === props.stateParsingInProgress ||
                true === loading
              }
              onClick={(e) => {
                setLoading(true);
                props.cbHandleSubmitAddStudents(e, props.statePresSchoolId);
                setLoading(false);
              }}
              style={{
                alignSelf: "center",
                padding: "6%",
                fontSize: 16,
                whiteSpace: "nowrap",
              }}
            >
              {!loading && <a>Add Students</a>}
              {loading && <img src={loadingIcon} alt="LOADING" />}
            </button>
          </div>
        </div>
      </>
    </ModalComponent>
  );
};

export default AddStudentsModalComponent;
