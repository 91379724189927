import styled from "@emotion/styled";
import { InputBase, alpha } from "@mui/material";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: 3,
  },
  "& .MuiInputBase-input": {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#F3F6F9",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 18,
    padding: "10px 12px",
    height: "30px",
    color: "#2C6AB1",
    fontFamily: ["Cardo"].join(","),
    "&:focus": {
      boxShadow: `${alpha("#2C6AB1", 0.25)} 0 0 0 0.2rem`,
      borderColor: "#fff",
    },
  },
}));
