import React, { ChangeEvent, useRef, useState } from "react";
import ModalComponent from "./ModalComponent";
import { loadingIcon } from "../../Assets/Images";

interface EditExamDateModalComponentProp {
  stateOpenEditIcon: {
    isOpen: boolean;
    selectedId: number | null;
  };
  stateExamDateInput: string;
  stateExamTimeInput: string;

  statePrevExamDateTimeStr: string;

  cbHandleCloseEditIcon: () => void;
  cbHandleInputExamDate: (e: ChangeEvent<HTMLInputElement>) => void;
  cbHandleInputExamTime: (e: ChangeEvent<HTMLInputElement>) => void;
  cbHandleSubmitChangeDateTime: (e: any) => void;
}

const EditExamDateModalComponent: React.FC<EditExamDateModalComponentProp> = (
  props: EditExamDateModalComponentProp
) => {
  console.log("In component EditExamDateModalComponent");
  const dateInputRef = useRef<HTMLInputElement>(null);
  const timeInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  return (
    <ModalComponent
      onClose={props.cbHandleCloseEditIcon}
      open={props.stateOpenEditIcon.isOpen}
      title="Change exam date and send notification mail"
    >
      <>
        <div
          style={{
            background: "white",
            borderRadius: 20,
            height: "100%",
            width: "100%",
            border: "none #000",
          }}
        >
          <div className="modal-inputs">
            <input
              ref={dateInputRef}
              onChange={props.cbHandleInputExamDate}
              name="date"
              className="input"
              type="text"
              value={props.stateExamDateInput?.split("T")[0]}
              placeholder={
                props.statePrevExamDateTimeStr
                  ? props.statePrevExamDateTimeStr.split(" ")[0]
                  : "Exam Date"
              }
              style={{
                paddingLeft: "20px",
                fontSize: "16px",
                fontWeight: "400",
                color: "#2C6AB1",
                width: "220px",
              }}
              onFocus={() => {
                if (dateInputRef.current) dateInputRef.current.type = "Date";
              }}
              onBlur={() => {
                if (dateInputRef.current) dateInputRef.current.type = "text";
              }}
            />
            <br />
            <input
              ref={timeInputRef}
              onChange={props.cbHandleInputExamTime}
              name="time"
              className="input"
              type="text"
              value={props.stateExamTimeInput}
              placeholder={
                props.statePrevExamDateTimeStr
                  ? props.statePrevExamDateTimeStr.split(" ")[1].split(":")[0] +
                    ":" +
                    props.statePrevExamDateTimeStr.split(" ")[1].split(":")[1]
                  : "Exam Time"
              }
              style={{
                paddingLeft: "20px",
                fontSize: "16px",
                fontWeight: "400",
                color: "#2C6AB1",
                width: "220px",
              }}
              onFocus={() => {
                if (timeInputRef.current) timeInputRef.current.type = "Time";
              }}
              onBlur={() => {
                if (timeInputRef.current) timeInputRef.current.type = "text";
              }}
            />
            {(!props.stateExamDateInput.length ||
              !props.stateExamTimeInput.length) && (
              <div
                style={{
                  color: "red",
                  display: "auto",
                  paddingTop: "10px",
                }}
              >
                Field/s can't be empty
              </div>
            )}
            <button
              className="modal-submit-button"
              onClick={(e) => {
                setLoading(true);
                props.cbHandleSubmitChangeDateTime(e);
                setLoading(false);
              }}
              style={{
                alignSelf: "center",
                padding: "6%",
                fontSize: 16,
                whiteSpace: "nowrap",
              }}
            >
              {loading && <img src={loadingIcon} alt="Loading" />}
              {!loading && <a>Confirm and Update</a>}
            </button>
          </div>
        </div>
      </>
    </ModalComponent>
  );
};

export default EditExamDateModalComponent;
