import React from "react";
import bgdown from "../Assets/Images/bgdown.svg";
import "../css/List.css";

const PageFooter = () => {
  return (
    <div className="footer-container">
      <img src={bgdown} className="down" alt="" />
      <div className="footer-text">
        Streak Tech | All rights reserved | 2023
      </div>
    </div>
  );
};

export default PageFooter;
