import React, { ChangeEvent, useState } from "react";
import ModalComponent from "./ModalComponent";
import { BootstrapInput } from "../InputField";
import { loadingIcon } from "../../Assets/Images";

interface UpdateStudentPhoneNumModalComponentProp {
  stateOpenEditNumber: boolean;
  stateStudentEditDetails: { newMobileNumber: string; newEMail: string };
  stateStudentDetailsErrors: { number: string; email: string };

  cbHandleCloseEditNumber: () => void;
  cbHandleInputMobileNumber: (e: ChangeEvent<HTMLInputElement>) => void;
  cbHandleSubmitMobileNumber: (e: any) => void;
}

const UpdateStudentPhoneNumModalComponent: React.FC<
  UpdateStudentPhoneNumModalComponentProp
> = (props: UpdateStudentPhoneNumModalComponentProp) => {
  console.log("In component UpdateStudentPhoneNumModalComponent");
  const [loading, setLoading] = useState(false);
  return (
    <ModalComponent
      onClose={props.cbHandleCloseEditNumber}
      open={props.stateOpenEditNumber}
      title="Update Student Number"
    >
      <>
        <div
          style={{
            background: "white",
            borderRadius: 20,
            height: "fit-content",
            border: "none #000",
            margin: "20px",
          }}
        >
          <div className="modal-inputs">
            <BootstrapInput
              onChange={props.cbHandleInputMobileNumber}
              name="newMobileNumber"
              className="input"
              type="telephone"
              placeholder="10-digit Number"
              value={props.stateStudentEditDetails.newMobileNumber}
            />
            {!props.stateStudentEditDetails.newMobileNumber.length && (
              <div
                style={{
                  color: "red",
                  display: "auto",
                  marginTop: "10px",
                }}
              >
                Incorrect format / missing data
              </div>
            )}
            {props.stateStudentDetailsErrors.number.length != 0 && (
              <div
                style={{
                  color: "red",
                  display: "auto",
                  marginTop: "10px",
                }}
              >
                {props.stateStudentDetailsErrors.number}
              </div>
            )}
            <button
              className="modal-submit-button"
              disabled={!props.stateStudentEditDetails.newMobileNumber.length}
              onClick={(e) => {
                setLoading(true);
                props.cbHandleSubmitMobileNumber(e);
                setLoading(false);
              }}
              style={{
                alignSelf: "center",
                fontSize: 16,
                whiteSpace: "nowrap",
              }}
            >
              {loading && <img src={loadingIcon} alt="Loading" />}
              {!loading && <a>Update Number</a>}
            </button>
          </div>
        </div>
      </>
    </ModalComponent>
  );
};

export default UpdateStudentPhoneNumModalComponent;
