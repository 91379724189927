import React from 'react';
import ReactDOM, { createRoot } from "react-dom/client";
import './css/index.css';


/*
import Login from './Pages/Login'; 
import List from './Pages/List'; 
import StudentSearch from './Pages/StudentSearch';
*/
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
