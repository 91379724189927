import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import React, { createContext, useContext, useEffect, useState } from "react";
import "./css/App.css";
import Login from "./Pages/Login";
import List from "./Pages/List";
import StudentSearch from "./Pages/StudentSearch";
import { CircularProgress } from "@mui/material";
import { AdminContext } from "./Contexts/AdminContext";
import "./Utils/DefinitionsInterfaces";
import { format } from "date-fns";
import { getAdminDetails } from "./Utils/api/auth";
//export const AuthContext = createContext({} as any);

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/List" element={<List />} />
      </Routes>
    </Router>
  );
  /*
    return (false) ? (
        <Router basename='/'>
            <Routes>
                <Route path='*' element={<Navigate to={"/List"} replace />} />
                <Route path='/List' element={<List /> } />
            </Routes>
        </Router>
    ) : (
        <Login />
    );
*/
};

function App() {
  const [authToken, setAuthToken] = useState("");
  const [authRefreshToken, setAuthRefreshToken] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [selectedExamState, setSelectedExamState] = useState<"level1" | "nationals">("level1")
  const [showStudent, setShowStudent] = useState(false);
  const [appLoading, setAppLoading] = useState(true);

  const [studentDetails, setStudentDetails] = useState<StudentDetails>({
    batch_id: "",
    created_at: "",
    email: "",
    examDate: "",
    exam_notification_sent: false,
    grade: 0,
    invite_sent: false,
    name: "",
    notify_for_new_mock_olympiads: false,
    paid_for_mock_olympiad: false,
    phone: "",
    report_generated: false,
    report_id: 0,
    school: "",
    school_id: 0,
    student_username: "",
  });

  const currentDate = new Date();
  console.log("Current Date = ", currentDate);
  const formattedDate = format(currentDate, "EEE dd MMM yyyy");
  const [currDate, setCurrDate] = useState(currentDate);
  const [currFormattedDate, setCurrFormattedDate] = useState(formattedDate);

  const checkExistingSession = async () => {
    const storedAdminAccessToken = localStorage.getItem("nfoAdminAccessToken");
    const storedAdminRefreshToken = localStorage.getItem(
      "nfoAdminRefreshToken"
    );
    if (storedAdminAccessToken && storedAdminRefreshToken) {
      const isSessionValid = await getAdminDetails();
      if (isSessionValid) {
        console.log("Session is valid");
        setIsLoggedIn(true);
      } else {
        console.log("Session Not Valid");
        setIsLoggedIn(false);
      }
    }
  };

  useEffect(() => {
    setAppLoading(true);
    checkExistingSession();
    setTimeout(() => {
      setAppLoading(false);
    }, 1000);
  }, []);

  console.log("Deployment : 14th May, 2024: 11:35am");

  return (
    <div className="App">
      {appLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!appLoading && (
        <AdminContext.Provider
          value={{
            authToken,
            setAuthToken,
            authRefreshToken,
            setAuthRefreshToken,
            username,
            setUsername,
            currDate,
            currFormattedDate,
            setIsLoggedIn,
            setShowStudent,
            studentDetails,
            setStudentDetails,
            selectedExamState,
            setSelectedExamState,
            appLoading,
            setAppLoading,
          }}
        >


          {isLoggedIn ? showStudent ? <StudentSearch /> : <List /> : <Login />}
        </AdminContext.Provider>
      )}
    </div>
  );
}

export default App;
