import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  useEffect,
  useRef,
  useState,
} from "react";
import ModalComponent from "./ModalComponent";
//@ts-ignore
import ReactFileReader from "react-file-reader";
import api from "../../Utils/axios/axiosMW";
import { loadingIcon } from "../../Assets/Images";

interface AddSchoolModalComponentProp {
  stateOpenAddSchool: boolean;
  stateIsPlaceholderVisible: boolean;
  stateStudentsAddFilename: string;
  stateSchoolNameInput: string;
  stateSchoolCityInput: string;
  stateSchoolStateInput: string;
  stateSchoolPhoneInput: string;
  stateSchoolEmailIdInput: string;
  stateSchoolPhoneNumErrorMessage: string;
  stateSchoolEmailIdErrorMessage: string;
  stateExamDateInput: string;
  stateExamTimeInput: string;
  stateAddStudentsObject: ApiRequestAddStudents;
  stateCsvParseErrorMessage: string;
  stateParsingInProgress: boolean;

  callbackHandleCloseAddSchool: () => void;
  callbackHandleInputSchoolName: (e: ChangeEvent<HTMLInputElement>) => void;
  callbackHandleInputSchoolCity: (selectedSchoolCity: string) => void;
  callbackHandleInputSchoolState: (selectedSchoolState: string) => void;
  callbackHandleInputSchoolPhoneNum: (e: ChangeEvent<HTMLInputElement>) => void;
  callbackHandleInputSchoolEmailId: (e: ChangeEvent<HTMLInputElement>) => void;
  callbackSetIsPlaceholderVisible: (newState: boolean) => void;
  callbackHandleInputExamDate: (e: ChangeEvent<HTMLInputElement>) => void;
  callbackHandleInputExamTime: (e: ChangeEvent<HTMLInputElement>) => void;
  callbackHandleInputStudentsDuringAddSchool: (e: any) => void;
  callbackHandleSubmitAddSchoolAndStudents: (e: any) => void;
}

const AddSchoolModalComponent: React.FC<AddSchoolModalComponentProp> = (
  props: AddSchoolModalComponentProp
) => {
  console.log("In component AddSchoolModalComponent");
  const dateInputRef = useRef<HTMLInputElement>(null);
  const timeInputRef = useRef<HTMLInputElement>(null);
  const [listOfStates, setListOfStates] = useState<string[]>([]);
  const [listOfCities, setListOfCities] = useState<string[]>([]);
  const [presentState, setPresentState] = useState<string>("");
  const [presentCity, setPresentCity] = useState<string>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api
      .post(
        "get-states",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        try {
          if (
            response &&
            response.data &&
            response.data.status === "Success" &&
            response.data.data
          ) {
            setListOfStates(response.data.data);
            if (response.data.data.length > 0) {
              setPresentState(response.data.data[0]);
              props.callbackHandleInputSchoolState(response.data.data[0]);
              api
                .post(
                  "get-cities-for-state",
                  {
                    state: response.data.data[0],
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                )
                .then((response1) => {
                  try {
                    if (
                      response1 &&
                      response1.data &&
                      response1.data.status === "Success" &&
                      response1.data.data
                    ) {
                      console.log("STATES DONE ..... CITIES HERE");
                      setListOfCities(response1.data.data);
                      if (response1.data.data.length > 0) {
                        setPresentCity(response1.data.data[0]);
                        props.callbackHandleInputSchoolCity(
                          response1.data.data[0]
                        );
                      }
                    } else {
                      console.log(" CITIES FETCH ERROR  ");
                    }
                  } catch (err) {
                    console.log("Error getting and setting cities = ", err);
                  }
                });
            }
          }
        } catch (err) {
          console.log("Error getting states = ", err);
        }
      });
  }, [props.stateOpenAddSchool]);

  return (
    <ModalComponent
      onClose={props.callbackHandleCloseAddSchool}
      open={props.stateOpenAddSchool}
      title="Upload school CSV to provide access to portal"
      alignTop={true}
    >
      <>
        <div
          style={{
            background: "white",
            borderRadius: 20,
            height: "fit-content",
            border: "none #000",
          }}
        >
          <br />
          <div className="modal-inputs">
            <input
              onChange={props.callbackHandleInputSchoolName}
              name="School Name"
              className="input"
              style={{
                paddingLeft: "20px",
                fontSize: "16px",
                fontWeight: "400",
                color: "#2C6AB1",
                width: "220px",
              }}
              type="text"
              placeholder="School Name"
            />
            <br />
            <div className="selector-text">SELECT STATE</div>
            <select
              className="dropdown-texts"
              onChange={(e) => {
                console.log("State = ", e.target.value);
                setPresentState(e.target.value);
                props.callbackHandleInputSchoolState(e.target.value);
                api
                  .post(
                    "get-cities-for-state",
                    {
                      state: e.target.value,
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  )
                  .then((response) => {
                    try {
                      if (
                        response &&
                        response.data &&
                        response.data.status === "Success" &&
                        response.data.data
                      ) {
                        setListOfCities(response.data.data);
                        if (response.data.data.length > 0) {
                          setPresentCity(response.data.data[0]);
                          props.callbackHandleInputSchoolCity(
                            response.data.data[0]
                          );
                        }
                      }
                    } catch (err) {
                      console.log("Error getting and setting cities = ", err);
                    }
                  });
              }}
            >
              {listOfStates
                ? listOfStates.map((state, index) => {
                    return <option key={index}>{state}</option>;
                  })
                : ""}
            </select>
            <br />
            <div className="selector-text">SELECT CITY</div>
            <select
              className="dropdown-texts"
              onChange={(e) => {
                console.log("Present city = ", e.target.value);
                setPresentCity(e.target.value);
                props.callbackHandleInputSchoolCity(e.target.value);
              }}
            >
              {listOfCities
                ? listOfCities.map((city, index) => {
                    return <option key={index}>{city}</option>;
                  })
                : ""}
            </select>
            <br />
            <input
              onChange={props.callbackHandleInputSchoolPhoneNum}
              name="Phone"
              className="input"
              style={{
                paddingLeft: "20px",
                fontSize: "16px",
                fontWeight: "400",
                color: "#2C6AB1",
                width: "220px",
              }}
              type="number"
              placeholder="Phone (Optional) "
            />
            <br />
            <input
              onChange={props.callbackHandleInputSchoolEmailId}
              name="Email Id"
              className="input"
              style={{
                paddingLeft: "20px",
                fontSize: "16px",
                fontWeight: "400",
                color: "#2C6AB1",
                width: "220px",
              }}
              type="email"
              placeholder="Email Id (Optional)"
            />
            <br />
            <input
              ref={dateInputRef}
              onChange={props.callbackHandleInputExamDate}
              name="date"
              id="dateInput"
              className="input"
              style={{
                paddingLeft: "20px",
                width: "220px",
                fontSize: "18px",
              }}
              type="text"
              placeholder="Exam Date (Optional)"
              title="Exam Date"
              onFocus={() => {
                if (dateInputRef.current) dateInputRef.current.type = "Date";
              }}
              onBlur={() => {
                if (dateInputRef.current) dateInputRef.current.type = "text";
              }}
            />
            <br />
            <input
              ref={timeInputRef}
              onChange={props.callbackHandleInputExamTime}
              name="time"
              id="timeInput"
              className="input"
              style={{
                paddingLeft: "20px",
                width: "220px",
                fontSize: "18px",
              }}
              type="text"
              placeholder="Exam Time (Optional)"
              title="Exam Time"
              onFocus={() => {
                if (timeInputRef.current) timeInputRef.current.type = "Time";
              }}
              onBlur={() => {
                if (timeInputRef.current) timeInputRef.current.type = "text";
              }}
            />
            <br />
            <ReactFileReader
              fileTypes={[".csv", ".zip"]}
              base64={true}
              handleFiles={props.callbackHandleInputStudentsDuringAddSchool}
            >
              <button
                className="input"
                style={{
                  justifyContent: "left",
                  width: "244px",
                  paddingLeft: "20px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#a0bada",
                  cursor: "pointer",
                  WebkitPaddingStart: "35px", //for chrome & edge. Firefox doesn't understand this
                  WebkitTextFillColor: "#2C6AB1",
                }}
              >
                {props.stateStudentsAddFilename}
              </button>
            </ReactFileReader>
            {(!props.stateSchoolNameInput.length ||
              !props.stateSchoolCityInput.length ||
              !props.stateSchoolStateInput.length) && (
              // || props.stateStudentsAddFilename === "Upload Student CSV"
              <div
                style={{
                  color: "red",
                  display: "auto",
                  marginTop: "10px",
                }}
              >
                Incorrect format / missing data
              </div>
            )}
            {"Upload Student CSV" !== props.stateStudentsAddFilename &&
              false === props.stateParsingInProgress &&
              0 === props.stateAddStudentsObject.students.length && (
                <div
                  style={{
                    color: "red",
                    display: "auto",
                  }}
                >
                  File Empty / invalid contents
                </div>
              )}
            {"Upload Student CSV" !== props.stateStudentsAddFilename &&
              "" !== props.stateCsvParseErrorMessage &&
              false === props.stateParsingInProgress && (
                <div
                  style={{
                    color: "red",
                    display: "auto",
                  }}
                >
                  File Error: {props.stateCsvParseErrorMessage}
                </div>
              )}
            {"Upload Student CSV" !== props.stateStudentsAddFilename &&
              true === props.stateParsingInProgress && (
                <div
                  style={{
                    color: "green",
                    display: "auto",
                  }}
                >
                  PARSING IN PROGRESS ......
                </div>
              )}
            {"" !== props.stateSchoolPhoneNumErrorMessage && (
              <div
                style={{
                  color: "red",
                  display: "auto",
                }}
              >
                {props.stateSchoolPhoneNumErrorMessage}
              </div>
            )}
            {"" !== props.stateSchoolEmailIdErrorMessage && (
              <div
                style={{
                  color: "red",
                  display: "auto",
                }}
              >
                {props.stateSchoolEmailIdErrorMessage}
              </div>
            )}
            <button
              className="modal-submit-button"
              disabled={
                !props.stateSchoolNameInput.length ||
                // props.stateStudentsAddFilename === "Upload Student CSV" ||
                "" === props.stateSchoolCityInput ||
                "" === props.stateSchoolStateInput ||
                "" !== props.stateSchoolPhoneNumErrorMessage ||
                "" !== props.stateSchoolEmailIdErrorMessage ||
                "" !== props.stateCsvParseErrorMessage ||
                // 0 === props.stateAddStudentsObject.students.length ||
                true === props.stateParsingInProgress ||
                true === loading
              }
              onClick={(e) => {
                setLoading(true);
                props.callbackHandleSubmitAddSchoolAndStudents(e);
                setLoading(false);
              }}
              style={{
                alignSelf: "center",
                padding: "6%",
                fontSize: 16,
                whiteSpace: "nowrap",
              }}
            >
              {loading && <img src={loadingIcon} alt="LOADING" />}
              {!loading && <a>Add School</a>}
            </button>
          </div>
        </div>
      </>
    </ModalComponent>
  );
};

export default AddSchoolModalComponent;
