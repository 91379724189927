import React, { useContext, useEffect, useState } from "react";
import ModalComponent from "./ModalComponent";
import { BootstrapInput } from "../InputField";
import api from "../../Utils/axios/axiosMW";
import { loadingIcon } from "../../Assets/Images";
import { AdminContext } from "../../Contexts/AdminContext";

interface SendInviteModalComponentProp {
  stateOpenSendInvite: boolean;
  schoolID?: string;
  studentUserName?: string;
  warningText: string;
  selectedExamState: string;

  cbHandleCloseSendInvite: () => void;
}

const SendInviteModalComponent: React.FC<SendInviteModalComponentProp> = (
  props: SendInviteModalComponentProp
) => {
  const { selectedExamState } = useContext(AdminContext)

  const [loadingState, setLoadingState] = useState(false);
  const [apiErrors, setApiErrors] = useState("");

  useEffect(() => {
    setApiErrors("");
  }, [props.stateOpenSendInvite]);

  const sendInviteToAllStudents = (schoolID: string) => {
    api
      .post(
        "invite-students-for-scholl",
        {
          schoolID: parseInt(schoolID),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Response on inviting all students = ", response);
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.status === "Success"
        ) {
          console.log(
            "Congrats! Invite sent to all students of school with Id = ",
            schoolID
          );
          setApiErrors("");
          props.cbHandleCloseSendInvite();
        } else {
          console.log("Invite not sent. Some Error Occured");
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.message
          ) {
            setApiErrors(response.data.data.message);
          } else {
            setApiErrors("Some Error Occured !");
          }
        }
      })
      .catch((err) => {
        console.log("Couldn't send invite. Error ! = ", err);
        if (
          err &&
          //@ts-ignore
          err.response && //@ts-ignore
          err.response.data && //@ts-ignore
          err.response.data.message
        ) {
          //@ts-ignore
          setApiErrors(err.response.data.message);
        } else {
          setApiErrors("Some error Occured !");
        }
      });
  };

  const sendInviteToAllStudentsNationals = () => {
    api.post(
      "nationals-send-comms",
      {
        "individual": false,
        "comm_type": "student_invite"
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          alert("Congrats! Invite sent to all nationals students");
          setApiErrors("");
          props.cbHandleCloseSendInvite();
        } else {
          console.log("Nationals Invite not sent. Some Error Occured");
          if (response.data.data.message) {
            setApiErrors(response.data.data.message);
          } else {
            setApiErrors("Some Error Occured !");
          }
        }
      })
      .catch((err) => {
        console.log("Couldn't send invite. Error ! = ", err);
        if (err.response.data.message) {
          setApiErrors(err.response.data.message);
        } else {
          setApiErrors("Some error Occured !");
        }
      });
  }

  const sendInviteToIndividualStudent = (studentUserNameString: string) => {
    api
      .post(
        "invite-student",
        {
          studentUserName: studentUserNameString,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Response on inviting student = ", response);
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.status === "Success"
        ) {
          setApiErrors("");
          console.log(
            "Congrats! Invite sent to student with roll number = ",
            studentUserNameString
          );
          props.cbHandleCloseSendInvite();
        } else {
          console.log("Invite not sent. Some Error Occured");
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.message
          ) {
            setApiErrors(response.data.data.message);
          } else {
            setApiErrors("Some Error Occured !");
          }
        }
      })
      .catch((err) => {
        console.log("Couldn't send invite. Error ! = ", err);
        if (
          err &&
          //@ts-ignore
          err.response && //@ts-ignore
          err.response.data && //@ts-ignore
          err.response.data.message
        ) {
          //@ts-ignore
          setApiErrors(err.response.data.message);
        } else {
          setApiErrors("Some Error Occured !");
        }
      });
  };

  const sendInviteToIndividualStudentNationals = (studentUserNameString: string) => {
    api.post(
      "nationals-send-comms",
      {
        "individual": true,
        "comm_type": "student_invite",
        "username": studentUserNameString
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          alert(`Congrats! Invite sent to Nationals student: ${studentUserNameString}`);
          setApiErrors("");
          props.cbHandleCloseSendInvite();
        } else {
          console.log("Nationals Invite not sent. Some Error Occured");
          if (response.data.data.message) {
            setApiErrors(response.data.data.message);
          } else {
            setApiErrors("Some Error Occured !");
          }
        }
      })
      .catch((err) => {
        console.log("Couldn't send invite. Error ! = ", err);
        if (err.response.data.message) {
          setApiErrors(err.response.data.message);
        } else {
          setApiErrors("Some error Occured !");
        }
      });
  }

  return (
    <ModalComponent
      open={props.stateOpenSendInvite}
      onClose={props.cbHandleCloseSendInvite}
      title="Are you Sure ?"
    >
      <>
        <div
          style={{
            background: "white",
            borderRadius: 20,
            height: "fit-content",

            border: "none #000",
            padding: "10px 20px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div className="modal-inputs">
            <div className="warning-text-invite">
              Are you Sure ?
              <br />
              {props.warningText}
            </div>
            {apiErrors.length > 0 && (
              <div className="warning-text-light">
                <br />
                {apiErrors}
              </div>
            )}
            <div className="invite-buttons-wrapper">
              <button
                className="modal-submit-button"
                disabled={loadingState}
                onClick={() => {
                  setLoadingState(true);
                  if (props.schoolID) {
                    selectedExamState === 'level1' ?
                      sendInviteToAllStudents(props.schoolID)
                      : sendInviteToAllStudentsNationals()
                  } else if (props.studentUserName) {
                    selectedExamState === 'level1' ?
                      sendInviteToIndividualStudent(props.studentUserName)
                      : sendInviteToIndividualStudentNationals(props.studentUserName)
                  }
                  setLoadingState(false);
                }}
                style={{
                  alignSelf: "center",
                  padding: "4%",
                  fontSize: 16,
                  whiteSpace: "nowrap",
                }}
              >
                {loadingState && <img src={loadingIcon} alt="Loading" />}
                {!loadingState && <a>Yes, Send Email.</a>}
              </button>
              <button
                className="modal-submit-button"
                onClick={props.cbHandleCloseSendInvite}
                style={{
                  alignSelf: "center",
                  padding: "4%",
                  fontSize: 16,
                  whiteSpace: "nowrap",
                }}
              >
                Cancel and Return!
              </button>
            </div>
          </div>
        </div>
      </>
    </ModalComponent>
  );
};

export default SendInviteModalComponent;
