import React, { ChangeEvent, useContext, useState } from "react";
import bgup from "../Assets/Images/bgup.svg";
import logo from "../Assets/Images/Logo2.svg";
import icon from "../Assets/Images/icons8-edit.svg";
import "../css/List.css";
import "../Utils/DefinitionsInterfaces";
import "../css/StudentSearch.css";
import { TableView } from "../Component/Tableview2";
import ModalComponent from "../Component/ModalComponents/ModalComponent";
import { AdminContext } from "../Contexts/AdminContext";
import { BootstrapInput } from "../Component/InputField";
import { AxiosResponse } from "axios";
import UpdateStudentPhoneNumModalComponent from "../Component/ModalComponents/UpdateStudentPhoneNumModalComponent";
import UpdateStudentEmailModalComponent from "../Component/ModalComponents/UpdateStudentEmailModalComponent";
import PageFooter from "../Component/PageFooter";
import api from "../Utils/axios/axiosMW";
import SendInviteModalComponent from "../Component/ModalComponents/SendInviteModalComponent";
import NotifyWarningModalComponent from "../Component/ModalComponents/NotifyWarningModalComponent";
interface ListProps { }

const List: React.FC<ListProps> = () => {
  const [openEditNumber, setEditNumber] = useState(false);
  const [openEditEmail, setEditEmail] = useState(false);
  const [studentEditDetails, setStudentEditDetails] = useState({
    newMobileNumber: "",
    newEMail: "",
  });
  const [studentDetailsErrors, setStudentDetailsErrors] = useState<{
    number: string;
    email: string;
  }>({
    number: "",
    email: "",
  });

  const {
    authToken,
    username,
    currFormattedDate,
    setShowStudent,
    studentDetails,
    setStudentDetails,
    selectedExamState
  } = useContext(AdminContext);

  const examDateTimeString = studentDetails.examDate;
  console.log('datastr: ', studentDetails.examDate);
  
  const [examDate, examTime] = examDateTimeString.split(" ");
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [inviteWarningText, setInviteWarningText] = useState("");

  const [notifyWarningModalOpen, setNotifyWarningModalOpen] = useState(false);
  const [notifyWarningText, setNotifyWarningText] = useState("");

  console.log("Check Student ExamDate = ", examDate);
  console.log("Check Student ExamTime = ", examTime);

  const dateString = examDateTimeString;
  const examDateUnixTimestamp = Math.floor(
    new Date(dateString).getTime() / 1000
  );
  console.log("Exam date Unix Timestamp = ", examDateUnixTimestamp);

  const currentDate = new Date();
  const currentUnixTimestamp = Math.floor(currentDate.getTime() / 1000);
  console.log("Current date = ", currentDate);
  console.log("Current Timestamp = ", currentUnixTimestamp);
  var examStatusString = "";
  if (examDateUnixTimestamp < currentUnixTimestamp) {
    examStatusString = "Exam Completed";
  } else {
    examStatusString = "Exam pending";
  }

  const [isExamPending, setIsExamPending] = useState(
    examStatusString === "Exam pending"
  );

  function isValidDate(dateString: string) {
    const examDate = new Date(dateString);
    let validDate = true;
    if (!isNaN(examDate.getTime())) {
      const examDateUnixTimestamp = Math.floor(
        new Date(dateString).getTime() / 1000
      );
      console.log("Exam date Unix Timestamp = ", examDateUnixTimestamp);

      const currentDate = new Date();
      const currentUnixTimestamp = Math.floor(currentDate.getTime() / 1000);
      var examStatusString = "";
      if (examDateUnixTimestamp < currentUnixTimestamp) {
        validDate = false;
      } else {
        validDate = true;
      }
    } else {
      validDate = false;
    }
    return validDate;
  }

  const handleCloseEditNumber = () => {
    console.log("HandleCloseEditNumber Is Called ");
    setEditNumber(false);
    setStudentEditDetails(
      (prevState: { newMobileNumber: string; newEMail: string }) => ({
        ...prevState,
        newMobileNumber: "",
      })
    );
    if (studentDetailsErrors.number.length)
      setStudentDetailsErrors(
        (prevState: { number: string; email: string }) => ({
          ...prevState,
          number: "",
        })
      );
  };

  const handleOpenEditNumber = () => {
    setEditNumber(true);
  };

  const handleOpenInviteModal = () => {
    setInviteWarningText(`This will send Invite E-mail to this ${selectedExamState === 'nationals'? 'Nationals ': ''} student!`);
    setInviteModalOpen(true);
  };

  const handleCloseInviteModal = () => {
    setInviteModalOpen(false);
  };

  const handleOpenNotifyModal = () => {
    setNotifyWarningText(
      `This is send Exam Notification E-mail to this ${selectedExamState === 'nationals'? 'Nationals ': ''} student!`
    );
    setNotifyWarningModalOpen(true);
  };

  const handleCloseNotifyModal = () => {
    setNotifyWarningModalOpen(false);
  };

  const handleInputMobileNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setStudentEditDetails({ ...studentEditDetails, [name]: value });
    if (studentDetailsErrors.number.length)
      setStudentDetailsErrors(
        (prevState: { number: string; email: string }) => ({
          ...prevState,
          number: "",
        })
      );
  };

  const handleInputEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setStudentEditDetails({ ...studentEditDetails, [name]: value });
    if (studentDetailsErrors.email.length)
      setStudentDetailsErrors(
        (prevState: { number: string; email: string }) => ({
          ...prevState,
          email: "",
        })
      );
  };

  const refetchStudentData = async (
    //@ts-ignore
    e
  ) => {
    e.preventDefault();
    console.log("Will try getting student details");
    try {
      const rollNumber = studentDetails.student_username;
      console.log("The roll number = ", rollNumber);
      const response: AxiosResponse<ApiResponseStudentDetails> = await api.post(
        "get-student-details",
        {
          studentUserName: rollNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response = ", response);
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.status_code === 200 &&
        response.data.data
      ) {
        console.log("Success in refetching student data");
        setStudentDetails(response.data.data);
        setShowStudent(true);
      } else {
        throw "Invalid response for student data refetch";
      }
    } catch (error) {
      console.log("ERROR IN REFETCHING STUDENT DATE ", error);
      console.log("STUDENT OLD DATA IS BEING SHOWN");
    }
  };

  // For changing phone number
  const handleSubmitMobileNumber = async (
    //@ts-ignore
    e
  ) => {
    e.preventDefault();
    console.log("In function HandleSubmitMobileNumber");
    const newErrors: { number: string; email: string } = {
      number: "",
      email: "",
    };
    const regex = /^\d{10}$/;

    const { newMobileNumber } = studentEditDetails;
    if (!regex.test(newMobileNumber)) {
      newErrors.number = "Mobile number must be 10 digits";
    }
    setStudentDetailsErrors(newErrors);
    if (Object.keys(newErrors.number).length === 0) {
      try {
        console.log("submitting new mobile number");
        const response: AxiosResponse<ApiResponseEditDetail> = await api.post(
          "update-student-phone",
          {
            studentUserID: studentDetails.student_username,
            oldMobileNumber: studentDetails.phone,
            newMobileNumber: newMobileNumber,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Sent req for mob num update");
        if (response.data.status_code === 200) {
          console.log("Response for changing student details is success!");
          console.log("Response.data = ", response.data);
          console.log("Refetching student data");
          refetchStudentData(e);
          handleCloseEditNumber();
        }
      } catch (error) {
        console.log("Couldn't change mobile number");
        console.log(
          "Error in sending request / receiving response. Error = ",
          error
        );
        newErrors.number = "Error sending Request. Number not Changed";
      }
    }
  };

  const handleSubmitEmailId = async (
    //@ts-ignore
    e
  ) => {
    e.preventDefault();
    console.log("In function HandleSubmitEmailId");
    const newErrors: { number: string; email: string } = {
      number: "",
      email: "",
    };
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const { newEMail } = studentEditDetails;
    if (!regex.test(newEMail)) {
      newErrors.email = "Email ID format is not valid";
    }
    setStudentDetailsErrors(newErrors);
    if (Object.keys(newErrors.email).length === 0) {
      console.log("submitting new email id");
      try {
        const { newEMail } = studentEditDetails;
        const response: AxiosResponse<ApiResponseEditDetail> = await api.post(
          "update-student-email",
          {
            studentUserID: studentDetails.student_username,
            oldEmail: studentDetails.email,
            newEmail: newEMail,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Sent req for EmailId Update");
        if (response.data.status_code === 200) {
          console.log("Response for changing student details is success!");
          console.log("Response.data = ", response.data);
          console.log("Refetching student data");
          refetchStudentData(e);
          handleCloseEditEmail();
        }
      } catch (error) {
        console.log("Couldn't update Email.");
        console.log(
          "Error in sending request / receiving response. Error = ",
          error
        );
        newErrors.email = "Error sending Request. Email not Changed";
      }
    }
  };

  const handleCloseEditEmail = () => {
    console.log("HandleCloseEditEmail is called");
    setEditEmail(false);
    setStudentEditDetails(
      (prevState: { newMobileNumber: string; newEMail: string }) => ({
        ...prevState,
        newEMail: "",
      })
    );
    if (studentDetailsErrors.email.length)
      setStudentDetailsErrors(
        (prevState: { number: string; email: string }) => ({
          ...prevState,
          email: "",
        })
      );
  };

  const handleOpenEditEmail = () => {
    setEditEmail(true);
  };

  const handleBackClick = () => {
    console.log("Back to School list button Pressed");
    setShowStudent(false);
  };

  const rows = [
    { id: 1, header: "Student Name", col2: [studentDetails.name] },
    { id: 2, header: "Roll Number", col2: [studentDetails.student_username] },
    {
      id: 3,
      header: "Student Details",
      col2: [
        <div key="1">
          {studentDetails.phone}
          {
            selectedExamState === 'level1' &&
            <img
              src={icon}
              alt="Image 1"
              className="logo-button"
              style={{
                cursor: "pointer",
                marginTop: "0px",
              }}
              onClick={handleOpenEditNumber}
            />
          }
          <UpdateStudentPhoneNumModalComponent
            stateOpenEditNumber={openEditNumber}
            stateStudentEditDetails={studentEditDetails}
            stateStudentDetailsErrors={studentDetailsErrors}
            cbHandleCloseEditNumber={handleCloseEditNumber}
            cbHandleInputMobileNumber={handleInputMobileNumber}
            cbHandleSubmitMobileNumber={handleSubmitMobileNumber}
          />
        </div>,
        <div key="2">
          {studentDetails.email}
          {
            selectedExamState === 'level1' &&
            <img
              src={icon}
              alt="Image 2"
              className="logo-button"
              style={{
                cursor: "pointer",
              }}
              onClick={handleOpenEditEmail}
            />
          }
          <UpdateStudentEmailModalComponent
            stateOpenEditEmail={openEditEmail}
            stateStudentEditDetails={studentEditDetails}
            stateStudentDetailsErrors={studentDetailsErrors}
            cbHandleCloseEditEmail={handleCloseEditEmail}
            cbHandleInputEmail={handleInputEmail}
            cbHandleSubmitEmailId={handleSubmitEmailId}
          />
        </div>,
        <div key="3" className="student-details-buttons-wrapper">
          <button
            className="single-invite-button"
            onClick={handleOpenInviteModal}
          // disabled={!isValidDate(studentDetails.examDate)}
          >
            Send Invite
          </button>
          <SendInviteModalComponent
            warningText={inviteWarningText}
            studentUserName={studentDetails.student_username}
            stateOpenSendInvite={inviteModalOpen}
            cbHandleCloseSendInvite={handleCloseInviteModal}
            selectedExamState={selectedExamState}
          />
          <button
            className="single-notify-button"
            onClick={handleOpenNotifyModal}
            disabled={!isValidDate(studentDetails.examDate)}
          >
            Notify
          </button>
          <NotifyWarningModalComponent
            warningText={notifyWarningText}
            studentUserName={studentDetails.student_username}
            stateOpenNotifyModal={notifyWarningModalOpen}
            cbHandleCloseNotifyModal={handleCloseNotifyModal}
          />
        </div>,
      ],
    },
    { id: 4, header: "School", col2: [studentDetails.school] },
    //{ id: 5, header: "Exam Date", col2: ["01/23/12"] },
    //Show only exam date, not time
    {
      id: 5,
      header: "Exam Date",
      col2: studentDetails.examDate
        ? [
          new Date(
            studentDetails.examDate.substring(
              0,
              studentDetails.examDate.indexOf(" ")
            )
          ).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }),
        ]
        : ["Date not assigned"],
    },
    {
      id: 6,
      header: "Results",
      col2: [
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexGrow: "10",
          }}
        >
          <div>
            {isExamPending ? (
              <div>Exam pending</div>
            ) : studentDetails.report_generated ? (
              <div>Issued</div>
            ) : (
              <div>Unissued</div>
            )}
          </div>
          <button
            disabled={studentDetails.report_generated || isExamPending === true}
            style={{
              height: 30,
              width: 180,
              marginTop: 0,
              marginLeft: 40,
              fontSize: 18,
              fontWeight: 700,
              cursor:
                studentDetails.report_generated || isExamPending === true
                  ? "auto"
                  : "pointer",
              backgroundColor:
                studentDetails.report_generated || isExamPending === true
                  ? "#7CB9E8"
                  : "auto",
            }}
          >
            Issue Certificates
          </button>
        </div>,
      ],
    },
  ];

  function getRowClassName(params: any) {
    if (params.row.id === 4 || params.row.id === 5 || params.row.id === 6) {
      return "header-color";
    }
    return "";
  }

  const columns = [
    {
      field: "header",
      type: "string",
      width: 200,
      headerClassName: "header-color",
      renderCell: (params: any) => (
        <div
          className="header-color"
          style={{
            whiteSpace: "normal",
            textAlign: "left",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "col2",
      type: "string",
      headerClassName: "header-color",
      width: 380,
      renderCell: (params: any) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            whiteSpace: "normal",
            textAlign: "left",
            fontWeight: "700",
          }}
          className="data-color"
        >
          {params.value.map((item: any, index: number) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div
      className="studentSearch"
      style={{
        backgroundColor: "#F3F3F3",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="row">
        <div className="name" style={{ textAlign: "left" }}>
          Welcome {username}, <br />{" "}
          <div
            className="date"
            style={{ marginLeft: "0px", textAlign: "left" }}
          >
            {currFormattedDate}
          </div>
        </div>

        <img src={bgup} alt="" className="bg" />
        <img src={logo} className="log" alt="" />
      </div>

      <div className="contt" style={{ width: 587 }}>
        <div
          className="butt"
          style={{
            paddingBottom: "30px",
          }}
        >
          <button
            style={{
              cursor: "pointer",
              fontWeight: 700,
              fontSize: 18,
              width: "192px",
            }}
            onClick={handleBackClick}
          >
            Back to school list
          </button>
        </div>

        <div
          className="Student-table"
          style={{
            width: "100%",
            //  backgroundColor: "rgb(243, 243, 243)",
          }}
        >
          <TableView rows={rows} columns={columns} {...getRowClassName} />
        </div>
      </div>
      <PageFooter />
    </div>
  );
};

export default List;
