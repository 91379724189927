import React, { ChangeEvent, useEffect, useState } from "react";
import ModalComponent from "./ModalComponent";
import { loadingIcon } from "../../Assets/Images";
import api from "../../Utils/axios/axiosMW";
import { getAllTeachers } from "../../Utils/api/auth";


interface AddTeacherModalProps {
  stateOpenTeacherModal: boolean;
  stateSchoolId: string;

  cbHandleCloseTeacherModal: () => void;
  currentTeacherCount: number;

}

const AddTeacherModal = (props: AddTeacherModalProps) => {
  const [loading, setLoading] = useState(false);
  const [teacherName, setTeacherName] = useState("");
  const [teacherNameErrors, setTeacherNameErrors] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [phoneNumErrors, setPhoneNumErrors] = useState("");
  const [email, setEmail] = useState("");
  const [emailErrors, setEmailErrors] = useState("");
  const [apiErrors, setApiErrors] = useState("");

  const [numOfTeachers, setNumOfTeachers] = useState(0);
  const getAllTeachersValue = () => {
    getAllTeachers(+props.stateSchoolId)
      .then((response) => {
        setLoading(true);

        //@ts-ignore
        if (response.data.status === "Success") {
          //@ts-ignore
          const teachers = response.data.data.teachers;
          setNumOfTeachers(teachers.length);
          setLoading(false);
        } else {
          setLoading(false);
          throw new Error("Error getting teachers");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };


  useEffect(() => {
    setApiErrors("");
    setTeacherNameErrors("Name can't be empty");
    setPhoneNumErrors("Incorrect Phone Number Format");
    setEmailErrors("Incorrect Email Id format");
    getAllTeachersValue();

  }, [props.stateOpenTeacherModal]);

  const handleInputTeacherName = (e: ChangeEvent<HTMLInputElement>) => {
    const enteredName = e.target.value;
    setApiErrors("");
    setTeacherName(enteredName);
    console.log("Entered Teacher name = ", enteredName);
    let error = "";
    if (!enteredName.length) {
      error = "Teacher name can't be empty";
    }
    setTeacherNameErrors(error);
  };

  const handleInputPhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const enteredPhoneNum = e.target.value;
    setApiErrors("");
    setPhoneNum(enteredPhoneNum);
    console.log("Entered Teacher Phone = ", enteredPhoneNum);

    //check for phone number errors
    var phoneErrors = "";
    const regex = /^$|^\d{10}$/; //either empty or exactly 10 digits

    if (!regex.test(enteredPhoneNum)) {
      phoneErrors = "Incorrect phone number format";
    }
    setPhoneNumErrors(phoneErrors);
  };

  const handleInputEmailId = (e: ChangeEvent<HTMLInputElement>) => {
    const enteredEmail = e.target.value;
    setApiErrors("");
    setEmail(enteredEmail);
    console.log("Entered Teacher Email = ", enteredEmail);

    //check for email id errors
    var errors = "";
    const regex = /^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; //either empty or proper email id format

    if (!regex.test(enteredEmail)) {
      errors = "Incorrect Email Id format";
    }
    setEmailErrors(errors);
  };

  const handleAddTeacher = () => {
    api
      .post(
        "add-teacher",
        {
          schoolID: props.stateSchoolId,
          name: teacherName,
          phone: phoneNum,
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Response on adding teacher = ", response);
        if (response && response.status === 200) {
          console.log("Teacher added successfully");
          props.cbHandleCloseTeacherModal();
        } else {
          console.log("Something went wrong!");
          setApiErrors("Something Went Wrong");
        }
      })
      .catch((err) => {
        console.log("Somethin went wrong!");
        setApiErrors("Something went wrong!");
      });
  };

  return (
    <ModalComponent
      onClose={props.cbHandleCloseTeacherModal}
      open={props.stateOpenTeacherModal}
      title="Add teacher for School"
    >
      <>
        <div
          style={{
            background: "white",
            borderRadius: 16,
            height: "100%",
            width: "100%",
            border: "none #000",
          }}
        >

          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              flex: 1,

              textAlign: "center",
              marginBottom: "5px",
              color: "rgba(64, 120, 184, 1)",
            }}
          >
            Current Teacher Count : {numOfTeachers}
          </div>

          <div className="modal-inputs">
            <input
              onChange={handleInputTeacherName}
              name="Teacher's Name"
              className="input"
              style={{

                paddingLeft: "10px",

                fontSize: "16px",
                fontWeight: "400",
                color: "#2C6AB1",
                width: "220px",

                margin: "5px 5px 3px 5px",

              }}
              type="text"
              placeholder="Teacher's Name"
            />
            {(!teacherName.length || teacherNameErrors.length > 0) && (
              <div
                style={{
                  color: "red",


                }}
              >
                Teacher name can't be empty
              </div>
            )}

            <input
              onChange={handleInputPhoneNumber}
              name="Phone"
              className="input"
              style={{

                paddingLeft: "10px",

                fontSize: "16px",
                fontWeight: "400",
                color: "#2C6AB1",
                width: "220px",

                marginTop: "10px",

              }}
              type="number"
              placeholder="Phone "
            />
            {(!phoneNum.length || phoneNumErrors.length > 0) && (
              <div
                style={{
                  color: "red",
                  display: "auto",
                  paddingTop: "10px",
                }}
              >
                Invalid Phone Number Format
              </div>
            )}
            <input
              onChange={handleInputEmailId}
              name="Email Id"
              className="input"
              style={{

                paddingLeft: "10px",

                fontSize: "16px",
                fontWeight: "400",
                color: "#2C6AB1",
                width: "220px",

                marginTop: "10px",

              }}
              type="email"
              placeholder="Email Id"
            />
            {(!email.length || emailErrors.length > 0) && (
              <div
                style={{
                  color: "red",
                  display: "auto",
                  paddingTop: "10px",
                }}
              >
                Invalid Email format
              </div>
            )}
            {apiErrors.length > 0 && (
              <div
                style={{
                  color: "red",
                  display: "auto",
                  paddingTop: "10px",
                }}
              >
                <br />
                {apiErrors}
              </div>
            )}
            <br />
            {(!teacherName.length ||
              !phoneNum.length ||
              !email.length ||
              teacherNameErrors.length > 0 ||
              emailErrors.length > 0 ||
              phoneNumErrors.length > 0) && (
              <div
                style={{
                  color: "red",
                  display: "auto",
                  paddingTop: "10px",
                }}
              >
                Invalid Entries
              </div>
            )}
            <button
              className="modal-submit-button"
              disabled={
                !teacherName.length ||
                !phoneNum.length ||
                !email.length ||
                teacherNameErrors.length > 0 ||
                emailErrors.length > 0 ||
                phoneNumErrors.length > 0
              }
              onClick={(e) => {
                setLoading(true);
                handleAddTeacher();
                setLoading(false);
              }}
              style={{
                alignSelf: "center",
                padding: "6%",
                fontSize: 16,
                whiteSpace: "nowrap",
              }}
            >
              {loading && <img src={loadingIcon} alt="Loading" />}
              {!loading && <a>Add Teacher</a>}
            </button>
          </div>
        </div>
      </>
    </ModalComponent>
  );
};

export default AddTeacherModal;
